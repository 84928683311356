<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container" @click.stop v-if="getModalInfo">
        <div class="close-div">
          <img v-if="title !== 'IBAN Bilgisi'" @click="hide()" style="opacity: 1; cursor:pointer;width: 16px; height: 16px;"
               src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
        </div>
        <div class="warning-container">
          <p class="warning-title">{{title}}</p>
          <div class="separator"></div>
          <div class="warning-text" v-html="bodyText"></div>
          <div class="separator"></div>
          <div class="warning-action-button-container" v-if="actionButton.show">
            <button v-if="actionButton && actionButton.cancelButtonTitle" @click="hide()" class="back-button">{{actionButton.cancelButtonTitle}}</button>
            <button @click="nextAction()" class="next-button">{{actionButton.title}}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>

  import actions from "../../../store/actions";

  export default {
    name: "src-pages-modals-globalWarningModal-v1",
    data() {
      return {
        title: '',
        bodyText: '',
        actionButton: {
          show: false,
          title: '',
          url: '',
          cancelButtonTitle: ''
        },
        hideCloseButton:false
      }
    },
    methods: {
      hide() {
        if(this.actionButton.cancelEventName) {
          this.EventBus.$emit(this.actionButton.cancelEventName, true);
        }
        this.$store.commit(this.types.DISABLE_MODAL);
      },

      nextAction() {
        if(this.actionButton.eventName) {
          this.EventBus.$emit(this.actionButton.eventName, true);
        } else {
          this.$router.push(this.actionButton.url);
        }
        this.$store.commit(this.types.DISABLE_MODAL);
      }
    },
    computed: {
      getModalInfo() {
        if (!this.title) {
          const info = this.$store.state.activeModal.info;
          this.title = info.title;
          this.bodyText = info.bodyText;
          this.actionButton = info.actionButton;
        }
        return true;
      },
    },

    created() {
    }
  }

</script>

<style scoped lang="scss">

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .modal-container {
    font-family: 'sofia-pro';
    margin: auto;
    padding: 0;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px);
    max-width: 600px;
    max-height: 600px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #3a4653;
    /*overflow-y: scroll;*/
    position: relative;
    border-radius: 5px;
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .warning-container {
    padding-top: 40px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .warning-title {
    padding-left: 20px;
    font-weight: bold;
    font-size: 18px;
    color: #2D3640;
  }

  .separator {
    width: 100%;
    height: 0px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-bottom: solid 1px #e6eaea;
  }

  .warning-text {
    padding: 0 20px 0 20px;
    color: #2D3640;
    min-height: 100px;
    max-height: 400px;
    overflow-y: scroll;
    line-height: 1.44;
  }

  .warning-action-button-container {
    align-self: flex-end;
    padding-right: 30px;
  }

  @media screen and (max-width: 660px) {

    .modal-container {
      max-height: 300px;
    }
  }

  .back-button {
    width: 140px;
    height: 40px;
    border-radius: 2px;
    background-color: #2d3640;
    padding-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    margin-right: 20px;
  }

  .next-button {
    width: 140px;
    height: 40px;
    border-radius: 2px;
    background-color: #fd4056;
    padding-bottom: 2px;
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    border: none;
    &:hover {
      background-color: #f33e53 !important;
    }

    &:disabled {
      background-color: #999 !important;
    }
  }


</style>
